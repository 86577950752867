export const apiURL = "https://devs.defikingdoms.com//query_heroes"

export function decode(genes) {
    const abc = '123456789abcdefghijkmnopqrstuvwx'
    let buf = ''
    let base = 32n
    let mod = 0
    let genesBigInt = BigInt(genes)
    while (genesBigInt >= base) {
        mod = genesBigInt % base
        buf += abc[mod]
        genesBigInt = (genesBigInt - mod) / base
    }
    buf += abc[genesBigInt]
    buf = buf.padEnd(48, '1')
    let result = []
    for (let i = 0; i < buf.length; i++) result[i] = abc.indexOf(buf[i])
    return result
}

export function relamParse(relam) {
    if(relam==='SER') {
        return 'Serendale'
    }
    else if(relam==='CRY') {
        return 'Crystalvale'
    }
    else if(relam==='SER2') {
        return 'Serendale 2.0'
    }
    else {
        return "Relam Error"
    }
}