export const CLASSES = new Map([
            [0, "Warrior"],
            [1, "Knight"],
            [2, "Thief"],
            [3, "Archer"],
            [4, "Priest"],
            [5, "Wizard"],
            [6, "Monk"],
            [7, "Pirate"],
            [8, "Beserker"],
            [9, "Seer"],
            [10, "Legionnaire"],
            [11, "Scholar"],
            [16, "Paladin"],
            [17, "DarkKnight"],
            [18, "Summoner"],
            [19, "Ninja"],
            [20, "Shapeshifter"],
            [21, "Bard"],
            [24, "Dragoon"],
            [25, "Sage"],
            [26, "SpellBow"],
            [28, "DreadKnight"]
        ]);

export const PROFESSIONS = new Map([
        [0, "Mining"],
        [2, "Gardening"],
        [4, "Fishing"],
        [6, "Foraging"]
]);

export const STATS = new Map([
        [0, 'Strength'],
        [2, 'Agility'],
        [4, 'Intelligence'],
        [6, 'Wisdom'],
        [8, 'Luck'],
        [10, 'Vitality'],
        [12, 'Endurance'],
        [14, 'Dexterity']
]);

export const ELEMENTS = new Map([
        [0, 'Fire'],
        [2, 'Water'],
        [4, 'Earth'],
        [6, 'Wind'],
        [8, 'Lightning'],
        [10, 'Ice'],
        [12, 'Light'],
        [14, 'Dark']
]);

export const CLASSES_STATS = new Map([
        ["Warrior-Strength",7500],
        ["Warrior-Endurance",6500],
        ["Warrior-Intelligence",2000],
        ["Warrior-Dexterity",7000],
        ["Warrior-Luck",3500],
        ["Warrior-Agility",5000],
        ["Warrior-Vitality",6500],
        ["Warrior-Wisdom",2000],
        ["Knight-Strength",7000],
        ["Knight-Endurance",7500],
        ["Knight-Intelligence",2000],
        ["Knight-Dexterity",5500],
        ["Knight-Luck",3500],
        ["Knight-Agility",4500],
        ["Knight-Vitality",7500],
        ["Knight-Wisdom",2500],
        ["Thief-Strength",5500],
        ["Thief-Endurance",4500],
        ["Thief-Intelligence",2500],
        ["Thief-Dexterity",5500],
        ["Thief-Luck",6500],
        ["Thief-Agility",7000],
        ["Thief-Vitality",5000],
        ["Thief-Wisdom",3500],
        ["Archer-Strength",5500],
        ["Archer-Endurance",6000],
        ["Archer-Intelligence",4000],
        ["Archer-Dexterity",8000],
        ["Archer-Luck",4000],
        ["Archer-Agility",5000],
        ["Archer-Vitality",5000],
        ["Archer-Wisdom",2500],
        ["Priest-Strength",3000],
        ["Priest-Endurance",6000],
        ["Priest-Intelligence",7000],
        ["Priest-Dexterity",3000],
        ["Priest-Luck",4000],
        ["Priest-Agility",4000],
        ["Priest-Vitality",5000],
        ["Priest-Wisdom",8000],
        ["Wizard-Strength",3000],
        ["Wizard-Endurance",5000],
        ["Wizard-Intelligence",8000],
        ["Wizard-Dexterity",3000],
        ["Wizard-Luck",4000],
        ["Wizard-Agility",4000],
        ["Wizard-Vitality",5000],
        ["Wizard-Wisdom",8000],
        ["Monk-Strength",6000],
        ["Monk-Endurance",5500],
        ["Monk-Intelligence",2500],
        ["Monk-Dexterity",6000],
        ["Monk-Luck",3000],
        ["Monk-Agility",6000],
        ["Monk-Vitality",6000],
        ["Monk-Wisdom",5000],
        ["Pirate-Strength",7000],
        ["Pirate-Endurance",5500],
        ["Pirate-Intelligence",2000],
        ["Pirate-Dexterity",7000],
        ["Pirate-Luck",5500],
        ["Pirate-Agility",5000],
        ["Pirate-Vitality",6000],
        ["Pirate-Wisdom",2000]
])