import './HeroInfo.css'
import DisplayMyHeroesRecessive from "../../stubs/DisplayMyHeroesRecessive";
import { useState } from 'react';
import { useParams } from 'react-router-dom'


function MyHeroesRecessive() {
    const {walletAddress} = useParams()
    const [walletAddressState, setWalletAddressState] = useState(walletAddress);

    return (
        <div className="">
            <div className='text-center align-items-center d-flex flex-column'>
                <label>Wallet Address: <br/> ***Note: Needs to be copied from MetaMask or Harmony as the casing needs to match***</label>
                    <input className='ms-1 address-input' type="text" placeholder="0x0" value={walletAddressState === "0x0" ? "" : walletAddressState} onChange={e =>
                        setWalletAddressState(
                            e.target.value
                        )} />
            </div>
            <div className='text-center'>
                <button className='btn btn-primary m-2' type='button'>
                    <a href={"/myHeroesRecessive/" + walletAddressState} className="link">Submit</a>
                </button>
            </div>
            <DisplayMyHeroesRecessive walletAddress={walletAddress} />
        </div>
    );
}

export default MyHeroesRecessive;