import 'bootstrap/dist/css/bootstrap.min.css';
import DisplayHerosSummonChancesContract from "./DisplayHerosSummonChancesContract";
import { Rarity } from '../consts/rarity'
import { useState } from 'react';
import { GraphQLClient, gql } from 'graphql-request'

export default function DisplayHerosSummonChances({ summonerId, assistantId }) {

    const [summoner, setSummoner] = useState(null);
    const [assistant, setAssistant] = useState(null);
    const [loadingSummoner, setLoadingSummoner] = useState(true);
    const [loadingAssistant, setLoadingAssistant] = useState(true);

    if (summonerId === assistantId) {
        return (
            <div className="text-center">Hero Ids must be different</div>
        )
    }

    if (summonerId === "" || assistantId === "") {
        return (
            <div className="text-center">Hero doesnt have gene data</div>
        )
    }
    if(!summoner) {
        const url = 'https://api.defikingdoms.com/graphql'
        const query = gql`
        query getHero($heroId: ID!){
            hero(id: $heroId) {
            id
            mainClass
            subClass
            statGenes
            rarity
            summonerId {
                id
            }
            assistantId {
                id
            }
            owner {
                id
                name
                }
            }
        }`
        const variables = {heroId: summonerId}
        const client = new GraphQLClient(url)
        client.request(query, variables).then((data) => {
            setSummoner(data.hero)
            setLoadingSummoner(false)
        })
    }
    if(!assistant) {
        const url = 'https://api.defikingdoms.com/graphql'
        const query = gql`
        query getHero($heroId: ID!){
            hero(id: $heroId) {
            id
            mainClass
            subClass
            statGenes
            rarity
            summonerId {
                id
            }
            assistantId {
                id
            }
            owner {
                id
                name
                }
            }
        }`
        const variables = {heroId: assistantId}
        const client = new GraphQLClient(url)
        client.request(query, variables).then((data) => {
            setAssistant(data.hero)
            setLoadingAssistant(false)
        })
    }

    if (loadingSummoner || loadingAssistant) return <div className="text-center">Loading...</div>;
    // if (!summoner.length) return <div className="text-center">Error! Try refreshing</div>;AAA

    if (checkIfParents(summoner, assistant).length !== 0) {
        return (
            <div className="text-center">*** Note: {checkIfParents(summoner, assistant)} ***</div>
        )
    }

    if (summoner && assistant) {
        return (
            <div className="table-responsive mx-1">
                <DisplayHerosSummonChancesContract summonerStatGenes={summoner.statGenes} summonerRarity={getRarity(summoner.rarity)}
                    assistantStatGenes={assistant.statGenes} assistantRarity={getRarity(assistant.rarity)} />
            </div>
        )
    } else {
        return <div className="text-center">Hero doesnt have gene data</div>
    }

}

function getRarity(rarity) {
    return Rarity.get(rarity)
}

function checkIfParents(summoner, assistant) {

    //TODO: This is ugly. Fix it.
    console.log(summoner.summonerId.id)
    console.log(assistant.summonerId.id)
    console.log(summoner.assistantId.id)
    console.log(assistant.assistantId.id)
    if (assistant.summonerId.id !== "0" && (summoner.id === assistant.summonerId.id || summoner.id === assistant.summonerId.id)) {
        return summoner.id + " is " + assistant.id + "'s parent";
    } else if (summoner.summonerId.id !== "0" && (assistant.id === summoner.summonerId.id || assistant.id === summoner.summonerId.id)) {
        return assistant.id + " is " + summoner.id + "'s parent";
    } else if (assistant.summonerId.id !== "0" && (assistant.summonerId.id === summoner.summonerId.id || assistant.summonerId.id === summoner.assistantid)) {
        return "Heroes have the same parent: " + assistant.summonerId.id;
    } else if (summoner.summonerId.id !== "0" && (summoner.summonerId.id === assistant.summonerId.id || summoner.summonerId.id === assistant.assistantid)) {
        return "Heroes have the same parent: " + summoner.summonerId.id;
    } else if (assistant.assistantId.id !== "0" && (assistant.assistantId.id === summoner.summonerId.id || assistant.assistantId.id === summoner.assistantid)) {
        return "Heroes have the same parent: " + assistant.assistantId.id;
    } else if (summoner.assistantId.id !== "0" && (summoner.assistantId.id === assistant.summonerId.id || summoner.assistantId.id === assistant.assistantId.id)) {
        return "Heroes have the same parent: " + summoner.assistantId.id;
    } else {
        return "";
    }
}