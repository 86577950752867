import 'bootstrap/dist/css/bootstrap.min.css';
import { CLASSES, PROFESSIONS, STATS } from "../consts/classes";
import { decode, relamParse } from "../consts/consts";

function getHeroClass(heroClass) {
    return CLASSES.get(heroClass);
}

function getProfession(heroClass) {
    return PROFESSIONS.get(heroClass);
}

function getStats(stats) {
    return STATS.get(stats);
}

function DisplayHerosRecessiveContract({ statGenes, heroId, originRealm }) {
    console.log("statGenes:" + statGenes)
    const genes = decode(statGenes)
    const relam = relamParse(originRealm)

    return (
        <table className="table table-dark table-hover text-center">
            <thead>
                <tr>
                    <th>#{heroId}-{relam}</th>
                    <th>Class</th>
                    <th>SubClass</th>
                    <th>Profession</th>
                    <th>Green Boost</th>
                    <th>Blue Boost</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Dominant</td>
                    <td>{getHeroClass(genes[44])}</td>
                    <td>{getHeroClass(genes[40])}</td>
                    <td>{getProfession(genes[36])}</td>
                    <td>{getStats(genes[16])}</td>
                    <td>{getStats(genes[12])}</td>
                </tr>
                <tr>
                    <td>Recessive 1</td>
                    <td>{getHeroClass(genes[45])}</td>
                    <td>{getHeroClass(genes[41])}</td>
                    <td>{getProfession(genes[37])}</td>
                    <td>{getStats(genes[17])}</td>
                    <td>{getStats(genes[13])}</td>
                </tr>
                <tr>
                    <td>Recessive 2</td>
                    <td>{getHeroClass(genes[46])}</td>
                    <td>{getHeroClass(genes[42])}</td>
                    <td>{getProfession(genes[38])}</td>
                    <td>{getStats(genes[18])}</td>
                    <td>{getStats(genes[14])}</td>
                </tr>
                <tr>
                    <td>Recessive 3</td>
                    <td>{getHeroClass(genes[47])}</td>
                    <td>{getHeroClass(genes[43])}</td>
                    <td>{getProfession(genes[39])}</td>
                    <td>{getStats(genes[19])}</td>
                    <td>{getStats(genes[15])}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default DisplayHerosRecessiveContract;