import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import HeroRecessive from './components/heroes/HeroRecessive'
import SummonChances from './components/heroes/SummonChances';
import MyHeroesRecessive from './components/heroes/MyHeroesRecessive';
import DisplayBestMiningRewards from './stubs/DisplayBestMiningRewards';
// import Leaderboards from './stubs/DisplayLeaderboards';

function App() {

  return (
    <div className="App">
      <header className="App-header">
      </header>
      <div className="text-center align-items-center justify-content-center d-flex flex-lg-row flex-column" id="top-of-page">
        <div className="button">
          <div className="group">
            <div className="item">
              <a href="/heroRecessive/1" className="link">Hero Genes</a>
            </div>
          </div>
        </div>
        <div className="button">
          <div className="group">
            <div className="item">
              <a href="/myHeroesRecessive/0x0" className="link">My Heroes Genes</a>
            </div>
          </div>
        </div>
        <div className="button">
          <div className="group">
            <div className="item">
              <a href="/summonChances/1/2" className="link">Summon Chances</a>
            </div>
          </div>
        </div>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<div className='text-center homepage-div'>Click one of the buttons above to get started</div>} />
          <Route path="/heroRecessive/:heroId" element={<HeroRecessive />} />
          <Route path="/myHeroesRecessive/:walletAddress" element={<MyHeroesRecessive />} />
          <Route path="/summonChances/:summonerId/:assistantId" element={<SummonChances />} />    
          {/* <Route path="/heroGardeningRewards" element={<DisplayBestGardeningRewards type="all"/>} />       */}
          {/* <Route path="/myHeroGardeningRewards" element={<GardeningRewards type="all"/>} /> */}
          {/* TODO: Fix formula description. Add gold and jewel formulas */}
          <Route path="/heroMiningRewards" element={<DisplayBestMiningRewards type="all"/>} />
          {/* <Route path="/leaderboards" element={<Leaderboards />} />     */}
          {/* <Route path="/totalStats" element={<TotalStatsAll type="all"/>} /> */}
          {/* <Route path="/totalStats/:walletAddress" element={<TotalStatsWallet type="all"/>} /> */}
          {/* <Route path="/totalStatsHero" element={<TotalStatsHero type="all"/>} /> */}
          {/* <Route path="/totalStatsWallet" element={<TotalStatsWallet type="all"/>} /> */}
        </Routes>
      </BrowserRouter>
      <div className='text-center fs-6'>
        <div className=''>
          Tip Jar: 0x845C106CaFaaA8B5C0cbC683FDddA48A422EF494
        </div>
        <div>
          Created by: avgjoe40. Feel free to message me on Discord if you have questions.
        </div>
      </div>
    </div>
  );
}

export default App;
