import 'bootstrap/dist/css/bootstrap.min.css';
import { CLASSES, PROFESSIONS, STATS } from "../consts/classes";
import { RarityChanceByRarity, Rarity } from "../consts/rarity";
import { useState, useEffect } from "react";
import { decode } from "../consts/consts";

function getHeroClass(heroClassId) {
    return CLASSES.get(heroClassId);
}

function getProfession(professionId) {
    return PROFESSIONS.get(professionId);
}

function getStats(statId) {
    return STATS.get(statId);
}

// function getElements(elementId) {
//     return ELEMENTS.get(elementId);
// }

function DisplayHerosSummonChancesContract({ summonerStatGenes, assistantStatGenes, summonerRarity, assistantRarity }) {

    const [summonerGenes, setSummonerGenes] = useState([]);
    const [assistantGenes, setAssistantGenes] = useState([]);
    const [mainClassChances, setMainClassChances] = useState(new Map());
    const [subClassChances, setSubClassChances] = useState(new Map());
    const [professionChances, setProfessionChances] = useState(new Map());
    const [greenStatChances, setGreenStatChances] = useState(new Map());
    const [blueStatChances, setBlueStatChances] = useState(new Map());
    // const [elementChances, setElementChances] = useState(new Map());

    const mutationPercentChance = .25;

    //Redo this with use effect
    useEffect(() => {
        const summonerTx = decode(summonerStatGenes)
        setSummonerGenes(summonerTx);
        const assistantTx = decode(assistantStatGenes)
        setAssistantGenes(assistantTx);

        const geneChance = new Map([
            ["d", 75],
            ["r1", 18.75],
            ["r2", 4.6875],
            ["r3", 1.5625]
        ])

        let tempSummonerMainClassChances = new Map();
        let tempAssistantMainClassChances = new Map();
        let tempMainClassChances = new Map();
        putOrAddIfExists(44, geneChance.get("d"), tempSummonerMainClassChances, summonerTx);
        putOrAddIfExists(45, geneChance.get("r1"), tempSummonerMainClassChances, summonerTx);
        putOrAddIfExists(46, geneChance.get("r2"), tempSummonerMainClassChances, summonerTx);
        putOrAddIfExists(47, geneChance.get("r3"), tempSummonerMainClassChances, summonerTx);
        putOrAddIfExists(44, geneChance.get("d"), tempAssistantMainClassChances, assistantTx);
        putOrAddIfExists(45, geneChance.get("r1"), tempAssistantMainClassChances, assistantTx);
        putOrAddIfExists(46, geneChance.get("r2"), tempAssistantMainClassChances, assistantTx);
        putOrAddIfExists(47, geneChance.get("r3"), tempAssistantMainClassChances, assistantTx);

        //TODO: refactor this to use a function that is useable for sub class too
        for (let i = 0; i < 10; i = i + 2) {
            if (tempSummonerMainClassChances.has(i) && tempAssistantMainClassChances.has(i + 1)) {
                let mutationChances = tempSummonerMainClassChances.get(i) / 100 * tempAssistantMainClassChances.get(i + 1) / 100 * mutationPercentChance * 100;
                tempMainClassChances.set(i / 2 + 16, mutationChances * 2);
                tempMainClassChances.set(i, mutationChances * -1);
                tempMainClassChances.set(i + 1, mutationChances * -1);
            }
            if (tempSummonerMainClassChances.has(i + 1) && tempAssistantMainClassChances.has(i)) {
                let mutationChances = tempSummonerMainClassChances.get(i + 1) / 100 * tempAssistantMainClassChances.get(i) / 100 * mutationPercentChance * 100;
                tempMainClassChances.has(i / 2 + 16) ? tempMainClassChances.set(i / 2 + 16, tempMainClassChances.get(i / 2 + 16) + mutationChances * 2) : tempMainClassChances.set(i / 2 + 16, mutationChances * 2);
                tempMainClassChances.has(i) ? tempMainClassChances.set(i, tempMainClassChances.get(i) - (mutationChances)) : tempMainClassChances.set(i, mutationChances * -1);
                tempMainClassChances.has(i + 1) ? tempMainClassChances.set(i + 1, tempMainClassChances.get(i + 1) - (mutationChances)) : tempMainClassChances.set(i + 1, mutationChances * -1);
            }
        }
        for (let i = 16; i < 21; i = i + 2) {
            if (tempSummonerMainClassChances.has(i) && tempAssistantMainClassChances.has(i + 1)) {
                let mutationChances = tempSummonerMainClassChances.get(i) / 100 * tempAssistantMainClassChances.get(i + 1) / 100 * mutationPercentChance * 100;
                tempMainClassChances.set(i / 2 + 16, mutationChances * 2);
                tempMainClassChances.set(i, mutationChances * -1);
                tempMainClassChances.set(i + 1, mutationChances * -1);
            }
            if (tempSummonerMainClassChances.has(i + 1) && tempAssistantMainClassChances.has(i)) {
                let mutationChances = tempSummonerMainClassChances.get(i + 1) / 100 * tempAssistantMainClassChances.get(i) / 100 * mutationPercentChance * 100;
                tempMainClassChances.has(i / 2 + 16) ? tempMainClassChances.set(i / 2 + 16, tempMainClassChances.get(i / 2 + 16) + mutationChances * 2) : tempMainClassChances.set(i / 2 + 16, mutationChances * 2);
                tempMainClassChances.has(i) ? tempMainClassChances.set(i, tempMainClassChances.get(i) - (mutationChances)) : tempMainClassChances.set(i, mutationChances * -1);
                tempMainClassChances.has(i + 1) ? tempMainClassChances.set(i + 1, tempMainClassChances.get(i + 1) - (mutationChances)) : tempMainClassChances.set(i + 1, mutationChances * -1);
            }
        }
        for (let i = 24; i < 26; i = i + 2) {
            if (tempSummonerMainClassChances.has(i) && tempAssistantMainClassChances.has(i + 1)) {
                let mutationChances = tempSummonerMainClassChances.get(i) / 100 * tempAssistantMainClassChances.get(i + 1) / 100 * mutationPercentChance / 2 * 100;
                tempMainClassChances.set(i / 2 + 16, mutationChances * 2);
                tempMainClassChances.set(i, mutationChances * -1);
                tempMainClassChances.set(i + 1, mutationChances * -1);
            }
            if (tempSummonerMainClassChances.has(i + 1) && tempAssistantMainClassChances.has(i)) {
                let mutationChances = tempSummonerMainClassChances.get(i + 1) / 100 * tempAssistantMainClassChances.get(i) / 100 * mutationPercentChance / 2 * 100;
                tempMainClassChances.has(i / 2 + 16) ? tempMainClassChances.set(i / 2 + 16, tempMainClassChances.get(i / 2 + 16) + mutationChances * 2) : tempMainClassChances.set(i / 2 + 16, mutationChances * 2);
                tempMainClassChances.has(i) ? tempMainClassChances.set(i, tempMainClassChances.get(i) - (mutationChances)) : tempMainClassChances.set(i, mutationChances * -1);
                tempMainClassChances.has(i + 1) ? tempMainClassChances.set(i + 1, tempMainClassChances.get(i + 1) - (mutationChances)) : tempMainClassChances.set(i + 1, mutationChances * -1);
            }
        }

        putOrAddIfExists(44, geneChance.get("d"), tempMainClassChances, summonerTx);
        putOrAddIfExists(45, geneChance.get("r1"), tempMainClassChances, summonerTx);
        putOrAddIfExists(46, geneChance.get("r2"), tempMainClassChances, summonerTx);
        putOrAddIfExists(47, geneChance.get("r3"), tempMainClassChances, summonerTx);
        putOrAddIfExists(44, geneChance.get("d"), tempMainClassChances, assistantTx);
        putOrAddIfExists(45, geneChance.get("r1"), tempMainClassChances, assistantTx);
        putOrAddIfExists(46, geneChance.get("r2"), tempMainClassChances, assistantTx);
        putOrAddIfExists(47, geneChance.get("r3"), tempMainClassChances, assistantTx);

        setMainClassChances(tempMainClassChances);

        let tempSummonerSubClassChances = new Map();
        let tempAssistantSubClassChances = new Map();
        let tempSubClassChances = new Map();
        putOrAddIfExists(40, geneChance.get("d"), tempSummonerSubClassChances, summonerTx);
        putOrAddIfExists(41, geneChance.get("r1"), tempSummonerSubClassChances, summonerTx);
        putOrAddIfExists(42, geneChance.get("r2"), tempSummonerSubClassChances, summonerTx);
        putOrAddIfExists(43, geneChance.get("r3"), tempSummonerSubClassChances, summonerTx);
        putOrAddIfExists(40, geneChance.get("d"), tempAssistantSubClassChances, assistantTx);
        putOrAddIfExists(41, geneChance.get("r1"), tempAssistantSubClassChances, assistantTx);
        putOrAddIfExists(42, geneChance.get("r2"), tempAssistantSubClassChances, assistantTx);
        putOrAddIfExists(43, geneChance.get("r3"), tempAssistantSubClassChances, assistantTx);

        console.log(tempSummonerSubClassChances);
        console.log(tempAssistantSubClassChances);

        for (let i = 0; i < 10; i = i + 2) {
            if (tempSummonerSubClassChances.has(i) && tempAssistantSubClassChances.has(i + 1)) {
                let mutationChance = (tempSummonerSubClassChances.get(i) / 100 * tempAssistantSubClassChances.get(i + 1) / 100 * mutationPercentChance) * 100;
                tempSubClassChances.set(i / 2 + 16, mutationChance * 2);
                tempSubClassChances.set(i, mutationChance * -1);
                tempSubClassChances.set(i + 1, mutationChance * -1);
            }
            if (tempSummonerSubClassChances.has(i + 1) && tempAssistantSubClassChances.has(i)) {
                console.log(i)
                let mutationChances = (tempSummonerSubClassChances.get(i + 1) / 100 * tempAssistantSubClassChances.get(i) / 100 * mutationPercentChance) * 100
                tempSubClassChances.has(i / 2 + 16) ? tempSubClassChances.set(i / 2 + 16, tempSubClassChances.get(i / 2 + 16) + (mutationChances * 2)) : tempSubClassChances.set(i / 2 + 16, mutationChances * 2);
                tempSubClassChances.has(i) ? tempSubClassChances.set(i, tempSubClassChances.get(i) - (mutationChances)) : tempSubClassChances.set(i, mutationChances * -1);
                tempSubClassChances.has(i + 1) ? tempSubClassChances.set(i + 1, tempSubClassChances.get(i + 1) - (mutationChances)) : tempSubClassChances.set(i + 1, mutationChances * -1);
            }
        }

        for (let i = 16; i < 21; i = i + 2) {
            if (tempSummonerSubClassChances.has(i) && tempAssistantSubClassChances.has(i + 1)) {
                let mutationChances = (tempSummonerSubClassChances.get(i) / 100 * tempAssistantSubClassChances.get(i + 1) / 100 * mutationPercentChance) * 100;
                tempSubClassChances.set(i / 2 + 16, mutationChances * 2);
                tempSubClassChances.set(i, mutationChances * -1);
                tempSubClassChances.set(i + 1, mutationChances * -1);
            }
            if (tempSummonerSubClassChances.has(i + 1) && tempAssistantSubClassChances.has(i)) {
                let mutationChances = (tempSummonerSubClassChances.get(i + 1) / 100 * tempAssistantSubClassChances.get(i) / 100 * mutationPercentChance) * 100
                tempSubClassChances.has(i / 2 + 16) ? tempSubClassChances.set(i / 2 + 16, tempSubClassChances.get(i / 2 + 16) + (mutationChances * 2)) : tempSubClassChances.set(i / 2 + 16, mutationChances * 2);
                tempSubClassChances.has(i) ? tempSubClassChances.set(i, tempSubClassChances.get(i) - (mutationChances)) : tempSubClassChances.set(i, mutationChances * -1);
                tempSubClassChances.has(i + 1) ? tempSubClassChances.set(i + 1, tempSubClassChances.get(i + 1) - (mutationChances)) : tempSubClassChances.set(i + 1, mutationChances * -1);
            }
        }

        for (let i = 24; i < 26; i = i + 2) {
            if (tempSummonerSubClassChances.has(i) && tempAssistantSubClassChances.has(i + 1)) {
                let mutationChances = (tempSummonerSubClassChances.get(i) / 100 * tempAssistantSubClassChances.get(i + 1) / 100 * mutationPercentChance / 2) * 100
                tempSubClassChances.set(i / 2 + 16, mutationChances * 2);
                tempSubClassChances.set(i, mutationChances * -1);
                tempSubClassChances.set(i + 1, mutationChances * -1);
            }
            if (tempSummonerSubClassChances.has(i + 1) && tempAssistantSubClassChances.has(i)) {
                let mutationChances = (tempSummonerSubClassChances.get(i + 1) / 100 * tempAssistantSubClassChances.get(i) / 100 * mutationPercentChance / 2) * 100
                tempSubClassChances.has(i / 2 + 16) ? tempSubClassChances.set(i / 2 + 16, tempSubClassChances.get(i / 2 + 16) + (mutationChances * 2)) : tempSubClassChances.set(i / 2 + 16, mutationChances * 2);
                tempSubClassChances.has(i) ? tempSubClassChances.set(i, tempSubClassChances.get(i) - (mutationChances)) : tempSubClassChances.set(i, mutationChances * -1);
                tempSubClassChances.has(i + 1) ? tempSubClassChances.set(i + 1, tempSubClassChances.get(i + 1) - (mutationChances)) : tempSubClassChances.set(i + 1, mutationChances * -1);
            }
        }

        putOrAddIfExists(40, geneChance.get("d"), tempSubClassChances, summonerTx);
        putOrAddIfExists(41, geneChance.get("r1"), tempSubClassChances, summonerTx);
        putOrAddIfExists(42, geneChance.get("r2"), tempSubClassChances, summonerTx);
        putOrAddIfExists(43, geneChance.get("r3"), tempSubClassChances, summonerTx);
        putOrAddIfExists(40, geneChance.get("d"), tempSubClassChances, assistantTx);
        putOrAddIfExists(41, geneChance.get("r1"), tempSubClassChances, assistantTx);
        putOrAddIfExists(42, geneChance.get("r2"), tempSubClassChances, assistantTx);
        putOrAddIfExists(43, geneChance.get("r3"), tempSubClassChances, assistantTx);
        setSubClassChances(tempSubClassChances)

        let tempProfessionChances = new Map();
        putOrAddIfExists(36, geneChance.get("d"), tempProfessionChances, summonerTx);
        putOrAddIfExists(37, geneChance.get("r1"), tempProfessionChances, summonerTx);
        putOrAddIfExists(38, geneChance.get("r2"), tempProfessionChances, summonerTx);
        putOrAddIfExists(39, geneChance.get("r3"), tempProfessionChances, summonerTx);
        putOrAddIfExists(36, geneChance.get("d"), tempProfessionChances, assistantTx);
        putOrAddIfExists(37, geneChance.get("r1"), tempProfessionChances, assistantTx);
        putOrAddIfExists(38, geneChance.get("r2"), tempProfessionChances, assistantTx);
        putOrAddIfExists(39, geneChance.get("r3"), tempProfessionChances, assistantTx);
        setProfessionChances(tempProfessionChances)

        let tempGreenStatChances = new Map();
        putOrAddIfExists(16, geneChance.get("d"), tempGreenStatChances, summonerTx);
        putOrAddIfExists(17, geneChance.get("r1"), tempGreenStatChances, summonerTx);
        putOrAddIfExists(18, geneChance.get("r2"), tempGreenStatChances, summonerTx);
        putOrAddIfExists(19, geneChance.get("r3"), tempGreenStatChances, summonerTx);
        putOrAddIfExists(16, geneChance.get("d"), tempGreenStatChances, assistantTx);
        putOrAddIfExists(17, geneChance.get("r1"), tempGreenStatChances, assistantTx);
        putOrAddIfExists(18, geneChance.get("r2"), tempGreenStatChances, assistantTx);
        putOrAddIfExists(19, geneChance.get("r3"), tempGreenStatChances, assistantTx);
        setGreenStatChances(tempGreenStatChances)

        let tempBlueStatChances = new Map();
        putOrAddIfExists(12, geneChance.get("d"), tempBlueStatChances, summonerTx);
        putOrAddIfExists(13, geneChance.get("r1"), tempBlueStatChances, summonerTx);
        putOrAddIfExists(14, geneChance.get("r2"), tempBlueStatChances, summonerTx);
        putOrAddIfExists(15, geneChance.get("r3"), tempBlueStatChances, summonerTx);
        putOrAddIfExists(12, geneChance.get("d"), tempBlueStatChances, assistantTx);
        putOrAddIfExists(13, geneChance.get("r1"), tempBlueStatChances, assistantTx);
        putOrAddIfExists(14, geneChance.get("r2"), tempBlueStatChances, assistantTx);
        putOrAddIfExists(15, geneChance.get("r3"), tempBlueStatChances, assistantTx);
        setBlueStatChances(tempBlueStatChances)

        //Need to verify this
        // let tempElementChances = new Map();
        // putOrAddIfExists(4, geneChance.get("d"), tempElementChances, summonerTx);
        // putOrAddIfExists(5, geneChance.get("r1"), tempElementChances, summonerTx);
        // putOrAddIfExists(6, geneChance.get("r2"), tempElementChances, summonerTx);
        // putOrAddIfExists(7, geneChance.get("r3"), tempElementChances, summonerTx);
        // putOrAddIfExists(4, geneChance.get("d"), tempElementChances, assistantTx);
        // putOrAddIfExists(5, geneChance.get("r1"), tempElementChances, assistantTx);
        // putOrAddIfExists(6, geneChance.get("r2"), tempElementChances, assistantTx);
        // putOrAddIfExists(7, geneChance.get("r3"), tempElementChances, assistantTx);
        // setElementChances(tempElementChances)
    }, [summonerStatGenes, assistantStatGenes, summonerGenes, assistantGenes, mainClassChances, greenStatChances, blueStatChances, professionChances, subClassChances])

let statBoostTr = getStatBoostTr(greenStatChances, blueStatChances);
let professionTr = getProfessionTr(professionChances);

// let elementTr = getElementTr(elementChances);

let baseClassTr = getBaseClassTr(mainClassChances, subClassChances);

let advancedClassTr = getAdvancedClassTr(mainClassChances, subClassChances);

let eliteClassTr = getEliteClassTr(mainClassChances, subClassChances);

let exaltedClassTr = getExaltedClassTr(mainClassChances, subClassChances);

let rarityTr = getRarityChanceByRarityTr(getRarityChanceByRarity(summonerRarity), getRarityChanceByRarity(assistantRarity));

return (
    <div>
        <table className="table table-dark table-hover w-100 text-center">
            <thead>
                <tr>
                    <th></th>
                    <th>MainClass</th>
                    <th>Sub Class</th>
                </tr>
            </thead>
            <tbody>
                {baseClassTr}
                {advancedClassTr}
                {eliteClassTr}
                {exaltedClassTr}
            </tbody>
        </table>
        <table className="table table-dark table-hover w-100 text-center">
            <thead>
                <tr>
                    <th>Profession</th>
                    <th>Chance</th>
                </tr>
            </thead>
            <tbody>
                {professionTr}
            </tbody>
        </table>
        <table className="table table-dark table-hover w-100 text-center">
            <thead>
                <tr>
                    <th>Stats</th>
                    <th>Green Chance</th>
                    <th>Blue Chance</th>
                </tr>
            </thead>
            <tbody>
                {statBoostTr}
            </tbody>
        </table>
        <table className="table table-dark table-hover w-100 text-center">
            <thead>
                <tr>
                    <th>Rarity</th>
                    <th>Chance</th>
                </tr>
            </thead>
            <tbody>
                {rarityTr}
            </tbody>
        </table>
    </div>
)
}

function getRarityChanceByRarity(rarity) {
    return RarityChanceByRarity.get(rarity)
}

function getRarity(rarity) {
    return Rarity.get(rarity)
}

function putOrAddIfExists(id, chance, map, genes) {
    map.has(genes[id]) ? map.set(genes[id], chance + map.get(genes[id])) : map.set(genes[id], chance);
}

function getStatBoostTr(greenStatChances, blueStatChances) {
    let statBoostTr = [];

    for (let i = 0; i < 16; i = i + 2) {
        statBoostTr.push(
            <tr key={"statBoost" + i}>
                <td>{getStats(i)}</td>
                <td>{greenStatChances.has(i) ? Number(Math.round(greenStatChances.get(i) * .5 + "e2") + "e-2") : 0.00}%</td>
                <td>{blueStatChances.has(i) ? Number(Math.round(blueStatChances.get(i) * .5 + "e2") + "e-2") : 0.00}%</td>
            </tr>
        )
    }

    return statBoostTr;
}

function getProfessionTr(professionChances) {
    let professionTr = [];

    for (let i = 0; i < 8; i = i + 2) {
        professionTr.push(
            <tr key={"profession" + i}>
                <td>{getProfession(i)}</td>
                <td>{professionChances.has(i) ? Number(Math.round(professionChances.get(i) * .5 + "e2") + "e-2") : 0}%</td>
            </tr>
        )
    }

    return professionTr;
}

// function getElementTr(elementChances) {
//     let elementTr = [];

//     for (let i = 0; i < 14; i = i + 2) {
//         elementTr.push(
//             <tr key={"element"+i}>
//                 <td>{getElements(i)}</td>
//                 <td>{elementChances.has(i) ? elementChances.get(i) * .5 : 0}%</td>
//             </tr>
//         )
//     }

//     return elementTr;
// }

function getBaseClassTr(mainClassChances, subClassChances) {
    let baseClassTr = []

    for (let i = 0; i < 10; i++) {
        baseClassTr.push(
            <tr key={"baseClass" + i}>
                <td>{getHeroClass(i)}</td>
                <td>{mainClassChances.has(i) ? Number(Math.round(mainClassChances.get(i) * .5 + "e2") + "e-2") : 0.00}%</td>
                <td>{subClassChances.has(i) ? Number(Math.round(subClassChances.get(i) * .5 + "e2") + "e-2") : 0.00}%</td>
            </tr>
        )
    }

    return baseClassTr
}

function getAdvancedClassTr(mainClassChances, subClassChances) {
    let advancedClassTr = [];

    for (let i = 16; i < 21; i++) {
        advancedClassTr.push(
            <tr key={"advancedClass" + i}>
                <td>{getHeroClass(i)}</td>
                <td>{mainClassChances.has(i) ? Number(Math.round(mainClassChances.get(i) * .5 + "e2") + "e-2") : 0.00}%</td>
                <td>{subClassChances.has(i) ? Number(Math.round(subClassChances.get(i) * .5 + "e2") + "e-2") : 0.00}%</td>
            </tr>
        )
    }

    return advancedClassTr;
}

function getEliteClassTr(mainClassChances, subClassChances) {
    let eliteClassTr = [];

    for (let i = 24; i < 26; i++) {
        eliteClassTr.push(
            <tr key={"eliteClass" + i}>
                <td>{getHeroClass(i)}</td>
                <td>{mainClassChances.has(i) ? Number(Math.round(mainClassChances.get(i) * .5 + "e2") + "e-2") : 0.00}%</td>
                <td>{subClassChances.has(i) ? Number(Math.round(subClassChances.get(i) * .5 + "e2") + "e-2") : 0.00}%</td>
            </tr>
        )
    }

    return eliteClassTr;
}

function getExaltedClassTr(mainClassChances, subClassChances) {
    let exaltedClassTr = [];

    for (let i = 28; i < 29; i++) {
        exaltedClassTr.push(
            <tr key={"exaltedClass" + i}>
                <td>{getHeroClass(i)}</td>
                <td>{mainClassChances.has(i) ? Number(Math.round(mainClassChances.get(i) * .5 + "e2") + "e-2") : 0.00}%</td>
                <td>{subClassChances.has(i) ? Number(Math.round(subClassChances.get(i) * .5 + "e2") + "e-2") : 0.00}%</td>
            </tr>
        )
    }

    return exaltedClassTr;
}

function getRarityChanceByRarityTr(summonerChances, assistantChances) {
    let rarityTr = [];
    for (let i = 0; i < 5; i++) {
        rarityTr.push(
            <tr key={"rarity" + i}>
                <td>{getRarity(i)}</td>
                <td>{summonerChances[i][1] + assistantChances[i][1]}%</td>
            </tr>
        )
    }
    return rarityTr;
}

export default DisplayHerosSummonChancesContract;