export const Rarity = new Map([
    [0, "Common"],
    [1, "Uncommon"],
    [2, "Rare"],
    [3, "Legendary"],
    [4, "Mythic"]
]);

export const RarityChanceByRarity = new Map([
    ["Common", ([["Common", 29.15], ["Uncommon", 13.5], ["Rare", 6.25], ["Legendary", 1.0], ["Mythic", 0.1]])],
    ["Uncommon", ([["Common", 26.85], ["Uncommon", 14.2], ["Rare", 6.9], ["Legendary", 1.55], ["Mythic", 0.5]])],
    ["Rare", ([["Common", 24.6], ["Uncommon", 14.9], ["Rare", 7.5], ["Legendary", 2.15], ["Mythic", 0.95]])],
    ["Legendary", ([["Common", 22.3], ["Uncommon", 15.55], ["Rare", 8.15], ["Legendary", 2.7], ["Mythic", 1.35]])],
    ["Mythic", ([["Common", 20], ["Uncommon", 16.25], ["Rare", 8.75], ["Legendary", 3.25], ["Mythic", 1.75]])]
])