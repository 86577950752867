import './HeroInfo.css'
import DisplayHerosRecessive from "../../stubs/DisplayHerosRecessive";
import { useState } from 'react';
import { useParams } from 'react-router-dom'

function HeroRecessive() {
    const { heroId } = useParams()
    const [heroIdState, setHeroIdState] = useState(heroId);

    return (
        <div className="">
            <div className='text-center align-items-center d-flex flex-column'>
                <label>
                    Hero Id:
                    <input className='ms-1' type="text" value={heroIdState} onChange={e => {
                        setHeroIdState(
                            e.target.value
                        )
                    }} />
                </label>
            </div>
            <div className='text-center'>
                <button className='btn btn-primary m-2' type='button'>
                    <a href={"/heroRecessive/" + heroIdState } className="link">Submit</a>
                </button>
            </div>
            <DisplayHerosRecessive heroId={heroId} />
        </div>
    );
}

export default HeroRecessive;