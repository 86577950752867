import './HeroInfo.css'
import DisplayHerosRecessive from "../../stubs/DisplayHerosRecessive";
import { useState } from 'react';
import DisplayHeroSummonChances from "../../stubs/DisplayHeroSummonChances"
import { useParams } from 'react-router-dom'


function SummonChances() {
    const { summonerId, assistantId } = useParams()
    const [summonerIdState, setSummonerIdState] = useState(summonerId);
    const [assistantIdState, setAssistanIdState] = useState(assistantId);

    return (
        <div className="">
            <div className='text-center align-items-center justify-content-center d-flex flex-sm-row flex-column'>
                <label>
                    Summoner Id:
                    <input className='ms-1' type="text" value={summonerIdState} onChange={e =>
                        setSummonerIdState(
                            e.target.value.trim()
                        )} />
                </label>
                <label className='ms-3'>
                    Assistant Id:
                    <input className='ms-1' type="text" value={assistantIdState} onChange={e =>
                        setAssistanIdState(
                            e.target.value.trim()
                        )} />
                </label>
            </div>
            <div className='text-center'>
                <button className='btn btn-primary m-2' type='button'>
                    <a href={"/summonChances/" + summonerIdState + "/" + assistantIdState} className="link">Submit</a>
                </button>
            </div>
            <DisplayHeroSummonChances summonerId={summonerId} assistantId={assistantId} />
            <DisplayHerosRecessive heroId={summonerId} />
            <DisplayHerosRecessive heroId={assistantId} />
            <div className='ms-3'>
                <div>Each gene is picked per trait, Class, Sub Class, Profession, etc. and there is a 50/50 chance that the summoned hero will have that dominant gene.</div>
                <div>Current chances per trait. D - 75%, R1 - 18.75%, R2- 4.6875%, R3 - 1.5625%</div>
                <div>If both the summoner and assistant pick classes with a chance for mutation there is a 25% chance of mutation. I.E. Pirate + Monk -&gt; Ninja</div>
                <div>For Sage + Dragoon -&gt; Dread Knight this is halved to 12.5%</div>
            </div>
        </div>
    );
}


export default SummonChances;