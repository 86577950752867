import 'bootstrap/dist/css/bootstrap.min.css';
import DisplayHerosRecessiveContract from "./DisplayHerosRecessiveContract";
import { useState } from 'react';
import { GraphQLClient, gql } from 'graphql-request'

function DisplayHerosRecessive({ heroId }) {

    const [hero, setHero] = useState(null);
    const [loading, setLoading] = useState(true);
    
    if(!hero) {
        const url = 'https://api.defikingdoms.com/graphql'
        const query = gql`
        query getHero($heroId: ID!){
            hero(id: $heroId) {
            id
            mainClass
            subClass
            statGenes
            originRealm
            owner {
                id
                name
                }
            }
        }`
        const variables = {heroId: heroId}
        const client = new GraphQLClient(url)
        client.request(query, variables).then((data) => {
            setHero(data.hero)
            setLoading(false)
        })
    }
    

    if (heroId === null) {
        return (
            <div className="text-center">Hero doesnt have gene data</div>
        )
    }

    if (loading) return <div className="text-center">Loading...</div>;
    // if (hero) return <div className="text-center">Error!</div>;

    if (!loading && hero) {
        return (
            <div className="table-responsive mx-1">
                <DisplayHerosRecessiveContract statGenes={hero.statGenes} heroId={heroId} originRealm={hero.originRealm}/>
            </div>
        )
    } else if(!hero) {
        return <div className="text-center">Hero doesnt have gene data</div>
    }

}

export default DisplayHerosRecessive;