
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Rarity } from '../consts/rarity'
import { CLASSES } from "../consts/classes";
import { apiURL } from "../consts/consts"
import React, { useState, useEffect } from 'react';
import axios from "axios";

function DisplayBestMiningRewards( ) {
    
    const [heroes, setHeroes] = useState();

    useEffect(() => {
         axios
                    .post(apiURL, {
                        "limit": 1000,
                        "params": [
                            
                        ],
                        "offset": 0,
                        "order": {
                            "orderBy": "mining",
                            "orderDir": "desc"
                        }
                    })
                    .then(responseSkill =>setHeroes(responseSkill.data))

    }, [ ]);
    
    if (!heroes) return <div className="text-center">Loading...</div>;
    if (!heroes.length) return <div className="text-center">Error!</div>;

    if (heroes) {
        return (
            <div className="table-responsive mx-1">
                <table className="table table-dark table-hover text-center">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Hero Id</th>
                            <th>Rarity</th>
                            <th>Class</th>
                            <th>Owner</th>
                            <th>Prof Gene</th>
                            <th>Level</th>
                            <th>Mining Stats <br/> (Total)</th>
                            <th>Mining <br/> Skill</th>
                            <th>Mining <br/> Score <sup><a href="#mining-score">2</a></sup></th>
                        </tr>
                    </thead>
                    <tbody>
                        {getHeroTable(heroes)}
                    </tbody>
                </table>
            </div>
        )
    } else {
        return <div className="text-center">Wallet address doesnt have hero data</div>
    }

}

function getHeroTable(heroes) {
    let heroMiningRewards = [];
    heroes.forEach(hero => {
        
        let heroId = hero.id;
        let heroProfession = hero.profession;
        let profBonus = heroProfession === "mining" ? 1.2 : 1;
        let miningSkill = Number(Math.floor(hero.mining/10))
        let miningSkillFull = hero.mining/10
        let miningStats = hero.endurance+hero.strength;
        let rarity = getRarity(hero.rarity);
        let level = hero.level
        let owner = hero.owner_name
        let miningScore = profBonus*(hero.endurance+hero.strength+(miningSkill*4));

        let mainClass = getClass(parseInt(hero.mainclass));
        heroMiningRewards.push({heroId, rarity, heroProfession, miningScore, 
            miningSkillFull, owner, mainClass, miningStats, level })
    });
    let heroTr = []
    let i = 1
    heroMiningRewards.sort((a,b) => (a.miningScore) < (b.miningScore) ? 1 : -1);
    heroMiningRewards.forEach(heroMiningRewards => {
        heroTr.push(
            <tr key={"heroProfessionScore-"+heroMiningRewards.heroId}>
                <td>{i}</td>
                <td>{heroMiningRewards.heroId}</td>
                <td>{heroMiningRewards.rarity}</td>
                <td>{heroMiningRewards.mainClass}</td>
                <td>{heroMiningRewards.owner}</td>
                <td>{heroMiningRewards.heroProfession}</td>
                <td>{heroMiningRewards.level}</td>
                <td>{heroMiningRewards.miningStats}</td>
                <td>{heroMiningRewards.miningSkillFull}</td>
                <td>{heroMiningRewards.miningScore}</td>
            </tr>
        )
        i++
    })
    return heroTr;
}

function getRarity(rarity) {
    return Rarity.get(rarity)
}

function getClass(classType) {
    return CLASSES.get(classType);
}

export default DisplayBestMiningRewards;