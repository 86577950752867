import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Rarity } from '../consts/rarity'
import { CLASSES, PROFESSIONS } from "../consts/classes";
import { useState } from 'react';
import { decode } from "../consts/consts";
import { GraphQLClient, gql } from 'graphql-request'


function DisplayMyHeroesRecessive( {walletAddress} ) {

    const [heroes, setHeroes] = useState(null);
    const [loading, setLoading] = useState(true);

    if(!heroes) {
        const url = 'https://api.defikingdoms.com/graphql'
        const query = gql`
        query getHeroesByOwner($walletAddress: String!){
            heroes(where: {owner: $walletAddress}) {
            id
            mainClass
            subClass
            statGenes
            rarity
            owner {
                id
                name
            }
          }}`
        const variables = {walletAddress: walletAddress}
        const client = new GraphQLClient(url)
        client.request(query, variables).then((data) => {
            setHeroes(data.heroes)
            setLoading(false)
        })
    }

    if (loading) return <div className="text-center">Loading...</div>;
    // if (error) return <div className="text-center">Error! ${error.message}</div>;

    if (heroes) {
        return (
            <div className="table-responsive mx-1">
                <table className="table table-dark table-hover text-center">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Hero Id</th>
                            <th>Rarity</th>
                            <th>Dominant</th>
                            <th>R1</th>
                            <th>R2</th>
                            <th>R3</th>
                            {/* <th>Green Stat</th>
                            <th>Blue Stat</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {getHeroTable(heroes)}
                    </tbody>
                </table>
            </div>
        )
    } else {
        return <div className="text-center">Wallet address doesnt have hero data</div>
    }

}

function getHeroTable(heroes) {
    let heroTr = [];
    heroes.forEach(hero => {
        let genes = decode(hero.statGenes)
        
        heroTr.push(
            <tr key={"heroProfessionScore-" + hero.id + "-Class"}>
                <td>Class</td>
                <td>{hero.id}</td>
                <td>{getRarity(hero.rarity) }</td>
                <td>{getHeroClass(genes[44])}</td>
                <td>{getHeroClass(genes[45])}</td>
                <td>{getHeroClass(genes[46])}</td>
                <td>{getHeroClass(genes[47])}</td>
            </tr>
        )
        heroTr.push(
            <tr key={"heroProfessionScore-" + hero.id + "-Sub-Class"}>
                <td>Sub Class</td>
                <td></td>
                <td></td>
                <td>{getHeroClass(genes[40])}</td>
                <td>{getHeroClass(genes[41])}</td>
                <td>{getHeroClass(genes[42])}</td>
                <td>{getHeroClass(genes[43])}</td>
            </tr>
        )
        heroTr.push(
            <tr key={"heroProfessionScore-" + hero.id + "-Profession"}>
                <td>Profession</td>
                <td></td>
                <td></td>
                <td>{getProfession(genes[36])}</td>
                <td>{getProfession(genes[37])}</td>
                <td>{getProfession(genes[38])}</td>
                <td>{getProfession(genes[39])}</td>
            </tr>
        )
        
    });
    return heroTr;
}

function getRarity(rarity) {
    return Rarity.get(rarity)
}

function getHeroClass(heroClass) {
    return CLASSES.get(heroClass);
}

function getProfession(heroClass) {
    return PROFESSIONS.get(heroClass);
}

// function getStats(stats) {
//     return STATS.get(stats);
// }

export default DisplayMyHeroesRecessive;